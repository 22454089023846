export default {
    configurations: {
        getList: ['GET', '/api/v1/configurations'],
        getOne: ['GET', '/api/v1/configurations/{id}'],
        toggleStatus: ['PUT', '/api/v1/configurations/{id}/toggle-status'],
        disconnect: ['DELETE', '/api/v1/configurations/connections/{id}/disconnect'],
        delete: ['DELETE', '/api/v1/configurations/{id}'],
    },

    email: {
        sendPreview: ['POST', '/api/v1/email/send-preview'],
    },

    google_oauth: {
        getConfig: ['GET', '/api/v1/oauth/google/config'],
        createGoogleConfiguration: ['POST', '/api/v1/oauth/google/callback'],
    },

    communityResponseTemplate: {
        getList: ['GET', '/api/v1/community-response-templates/search'],
        toggleStatus: ['PUT', '/api/v1/community-response-templates/toggle-status/{id}'],
        getOne: ['GET', '/api/v1/community-response-templates/get-by-id/{id}'],
        create: ['POST', '/api/v1/community-response-templates'],
        update: ['PUT', '/api/v1/community-response-templates'],
        delete: ['DELETE', '/api/v1/community-response-templates/{id}'],
        patch: ['PATCH', '/api/v1/community-response-templates/{id}'],
        getLeadSourceList: ['GET', '/api/v1/community-response-templates/get-active-lead-sources'],
        publish: ['PATCH', '/api/v1/community-response-templates/publish-template/{id}'],
    },
    outgoingclassification: {
        getAll: ['GET', '/api/v1/outgoing-classifications/get-all'],
        create: ['POST', '/api/v1/outgoing-classifications'],
        getOne: ['GET', '/api/v1/outgoing-classifications/get-by-id/{id}'],
        patch: ['PATCH', '/api/v1/outgoing-classifications/{id}'],
        getList: ['GET', '/api/v1/outgoing-classifications/search'],
        toggleStatus: ['PUT', '/api/v1/outgoing-classifications/toggle-status/{id}'],
        update: ['PATCH', '/api/v1/outgoing-classifications/{id}'],
    },
    portal: {
        getList: ['GET', '/api/v1/portals/search'],
        toggleStatus: ['PUT', '/api/v1/portals/toggle-status/{id}'],
        getOne: ['GET', '/api/v1/portals/get-by-id/{id}'],
        create: ['POST', '/api/v1/portals'],
        update: ['PUT', '/api/v1/portals'],
    },
    responseTemplate: {
        fileUpload: ['POST', '/api/v1/response-templates/file-upload'],
    },
    permissions: '/api/v1/my/permissions',
    authCommunity: {
        getOne: ['GET', '/service/auth/v1/customers/{customerId}/communities/{communityId}'],
    },
};
