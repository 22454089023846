import { createPermissionChecker, prepareIotRoutes } from './helper';
export default prepareIotRoutes([
    {
        name: 'iot.leases.index',
        path: '/iot/leases',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/leases/Index'),
        meta: {
            actions: true,
            breadcrumbName: 'leases',
            title: 'leases',
            flowStartsWith: 'iot.leases.index',
            permissionChecker: createPermissionChecker('LEASE_READ_LIST'),
        },
    },
    {
        name: 'iot.create-lease.index',
        path: '/iot/leases/new-lease',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/leases/CreateLease'),
        meta: {
            actions: true,
            breadcrumbName: 'new lease',
            title: 'leases',
            flowStartsWith: 'iot.leases.index',
            permissionChecker: createPermissionChecker('LEASE_CREATE'),
        },
    },
    {
        name: 'iot.lease-details.index',
        path: '/iot/leases/:leaseId',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/leases/LeaseDetails'),
        meta: {
            actions: true,
            breadcrumbName: 'lease details',
            title: 'lease details',
            flowStartsWith: 'iot.leases.index',
            permissionChecker: createPermissionChecker('LEASE_READ'),
        },
    },
    {
        name: 'iot.lease-renew.index',
        path: '/iot/leases/:leaseId/renew/:residentId?',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/leases/LeaseRenew'),
        meta: {
            actions: true,
            breadcrumbName: 'renew',
            title: 'renew',
            flowStartsWith: 'iot.leases.index',
            permissionChecker: createPermissionChecker('LEASE_RENEW'),
        },
    },
    {
        name: 'iot.lease-transfer.index',
        path: '/iot/leases/:leaseId/transfer',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/leases/LeaseTransfer'),
        meta: {
            actions: true,
            breadcrumbName: 'transfer',
            title: 'transfer',
            flowStartsWith: 'iot.leases.index',
            permissionChecker: createPermissionChecker('LEASE_TRANSFER'),
        },
    },
    {
        name: 'iot.lease-move-in.index',
        path: '/iot/leases/:leaseId/execute-move-in/:residentId',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/leases/LeaseMoveIn'),
        meta: {
            actions: true,
            breadcrumbName: 'execute move-in',
            title: 'execute move-in',
            flowStartsWith: 'iot.leases.index',
            permissionChecker: createPermissionChecker('LEASE_EXECUTE_MOVE_IN'),
        },
    },
    {
        name: 'iot.lease-move-out.index',
        path: '/iot/leases/:leaseId/execute-move-out/:residentId',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/leases/LeaseMoveOut'),
        meta: {
            actions: true,
            breadcrumbName: 'execute move-out',
            title: 'execute move-out',
            flowStartsWith: 'iot.leases.index',
            permissionChecker: createPermissionChecker('LEASE_EXECUTE_MOVE_OUT'),
        },
    },
    {
        name: 'iot.lease.authorizations',
        path: '/iot/leases/:id/authorizations',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/authorizations/leases/Index'),
        meta: {
            actions: true,
            breadcrumbName: 'authorizations',
            title: 'authorizations',
            flowStartsWith: 'iot.leases.index',
            permissionChecker: createPermissionChecker('LEASE_READ_ACCESSES'),
        },
    },
    {
        name: 'iot.lease.authorizations.add-location',
        path: '/iot/leases/:id/authorizations/add-location',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/authorizations/leases/AddLocation'),
        meta: {
            breadcrumbName: 'add location',
            title: 'add location',
            flowStartsWith: 'iot.leases.index',
            permissionChecker: createPermissionChecker('LEASE_UPDATE_ACCESSES'),
        },
    },
]);
