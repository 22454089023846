import { useEmailbotStore } from '@/stores/emailbot/emailbot';
import { EMAILBOT_PERMISSIONS } from '@/utils/constants/emailbot/permissions';

const createPermissionChecker = permissionId => () => {
    const hasPermission = useEmailbotStore().hasPermission;
    return hasPermission(permissionId);
};

export default [
    {
        name: 'emailbot.templates.index',
        path: '/emailbot/templates',
        component: () => import(/* webpackChunkName: "emailbot" */ '@/views/emailbot/templates/Index.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'templates',
            breadcrumbName: 'templates',
            flowStartsWith: 'emailbot.templates.index',
            product: 'emailbot',
            requiresCustomer: true,
            size: 'large',
            permissionChecker: () =>
                createPermissionChecker(EMAILBOT_PERMISSIONS.VIEW_EMAILBOT)() ||
                createPermissionChecker(EMAILBOT_PERMISSIONS.VIEW_COMMUNITY_TEMPLATE)() ||
                createPermissionChecker(EMAILBOT_PERMISSIONS.VIEW_CATEGORY)(),
        },
    },
    {
        path: '/emailbot/templates/create',
        name: 'emailbot.templates.create',
        component: () => import('@/views/emailbot/templates/Create.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add new template',
            title: 'add new template',
            flowStartsWith: 'emailbot.templates.index',
            hideFromSearch: true,
            requiresCustomer: true,
            size: 'large',
            permissionChecker: createPermissionChecker(EMAILBOT_PERMISSIONS.CREATE_COMMUNITY_TEMPLATE),
        },
    },
    {
        path: '/emailbot/templates/:templateId',
        name: 'emailbot.templates.edit',
        component: () => import('@/views/emailbot/templates/Details.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit template',
            title: 'edit template',
            hideFromSearch: true,
            flowStartsWith: 'emailbot.templates.index',
            requiresCustomer: true,
            size: 'large',
            permissionChecker: () =>
                createPermissionChecker(EMAILBOT_PERMISSIONS.VIEW_COMMUNITY_TEMPLATE)() ||
                createPermissionChecker(EMAILBOT_PERMISSIONS.EDIT_COMMUNITY_TEMPLATE)(),
        },
    },
    {
        path: '/template/preview',
        name: 'template.preview',
        component: () => import('@/views/emailbot/template/TemplatePreview.vue'),
        meta: {
            loadInModal: false,
            flowStartsWith: 'emailbot.templates.index',
            hideFromSearch: true,
            size: 'large',
            permissionChecker: createPermissionChecker(EMAILBOT_PERMISSIONS.VIEW_COMMUNITY_TEMPLATE),
        },
    },
    {
        path: '/template/unsubscribe/:unsubscribeToken',
        name: 'template.unsubscribe',
        component: () => import('@/views/emailbot/template/TemplateUnsubscribe.vue'),
        meta: {
            bareUnauthorized: true,
        },
    },
    {
        path: '/template/view/:templateId',
        name: 'template.view',
        component: () => import('@/views/emailbot/template/TemplatePreview.vue'),
        meta: {
            bareUnauthorized: true,
        },
    },
];
