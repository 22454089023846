import { createPermissionChecker, QUEXT_PERMISSIONS } from '@/components/auth/permissions';

export default [
    {
        path: '/dashboard/json-editor',
        name: 'dashboard.json-editor',
        component: () => import(/* webpackChunkName: "cbr" */ '@/views/integration_dashboard/json-editor/JsonEditor'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit json',
            title: 'edit json',
            flowStartsWith: 'dashboard.json-editor',
            product: 'dashboard',
            nonStacked: true,
            size: 'large',
            requiresCustomer: false,
            requiresCommunity: false,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_SUPERADMIN),
        },
        children: [
            {
                name: 'dashboard.json-editor.item',
                path: '/dashboard/json-editor/:id',
                component: () => import(/* webpackChunkName: "cbr" */ '@/views/integration_dashboard/json-editor/JsonEditor'),
                meta: {
                    title: 'edit json',
                    flowStartsWith: 'dashboard.json-editor',
                    product: 'dashboard',
                },
            },
        ],
    },
];
