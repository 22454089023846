import { useEmailbotStore } from '@/stores/emailbot/emailbot';
import { EMAILBOT_PERMISSIONS } from '@/utils/constants/emailbot/permissions';

const createPermissionChecker = permissionId => () => {
    const hasPermission = useEmailbotStore().hasPermission;
    return hasPermission(permissionId);
};

export default [
    {
        path: '/emailbot/bots',
        name: 'emailbot.configurations.index',
        component: () => import('@/views/emailbot/configurations/Index.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'configurations',
            title: 'configurations',
            flowStartsWith: 'mailbot.configurations.index',
            hideFromSearch: true,
            requiresCustomer: true,
            size: 'large',
            permissionChecker: () =>
                createPermissionChecker(EMAILBOT_PERMISSIONS.VIEW_EMAILBOT)() ||
                createPermissionChecker(EMAILBOT_PERMISSIONS.VIEW_COMMUNITY_TEMPLATE)() ||
                createPermissionChecker(EMAILBOT_PERMISSIONS.VIEW_CATEGORY)(),
        },
    },
];
