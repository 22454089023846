import { useEmailbotStore } from '@/stores/emailbot/emailbot';
import { EMAILBOT_PERMISSIONS } from '@/utils/constants/emailbot/permissions';

const createPermissionChecker = permissionId => () => {
    const hasPermission = useEmailbotStore().hasPermission;
    return hasPermission(permissionId);
};

export default [
    {
        routeName: 'emailbot.templates.index',
        items: [
            {
                title: 'add new',
                routeName: 'emailbot.templates.create',
                allowed: createPermissionChecker(EMAILBOT_PERMISSIONS.CREATE_COMMUNITY_TEMPLATE),
            },
        ],
    },
];
